import React, {useEffect, useState} from 'react';


import './App.css';

import Map from 'react-map-gl'; // eslint-disable-line import/no-webpack-loader-syntax

import  {Source, Layer, Marker } from 'react-map-gl';

import ProvinceLabel from './data/ProvinceLabel.geojson'
import ProvinceMap from './data/ProvinceMap.json'

function App() {
  const [viewport, setViewport] = useState({
    latitude: 28.2434542,
    longitude: 83.9835325,
    minZoom: 6.5,
    maxZoom: 20,
  });

  const MAPBOX_TOKEN = 'pk.eyJ1IjoieW9nZXNoa2Fya2kiLCJhIjoiY2txZXphNHNlMGNybDJ1cXVmeXFiZzB1eSJ9.A7dJUR4ppKJDKWZypF_0lA'

  const provinceLabelStyle = {
    id: "provinceLabel",
    type: 'symbol',
    layout: {
      "text-allow-overlap": true,
      'text-field': ['get', 'description'],
      'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
      'text-radial-offset': 0.5,
      'text-justify': 'auto',
      "text-size": 14,
    }

  }

  const mapStyleLine = {
    id: 'map_style',
    type: 'line',
    layout: {
    },
    paint: {
        'line-color': "#0a405a",
        'line-width': 1.5
    },
    
  }


  return (
    <>
        <Map
          {...viewport}
          style={{width: '100vw', height: '100vh'}}
          mapboxAccessToken={MAPBOX_TOKEN}
          mapStyle="mapbox://styles/yogeshkarki/cl0gcwa4e000d15p3bh6inkcq"
          onMove={evt => setViewport(evt.viewport)} 
        > 

          <Source id="provinceLabel" type="geojson" data={ProvinceLabel}>
            <Layer {...provinceLabelStyle}/>
          </Source>

          <Source id="nepalMap" type='geojson' data={ProvinceMap}>
            <Layer {...mapStyleLine} />
          </Source>
 
          
        </Map>
    </>
  );
}

export default App;
